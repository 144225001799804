import './Epiloggs.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {deleteEpilogg, getEpiloggs, undeleteEpilogg} from "./store/actions";
import InfiniteScroll from 'react-infinite-scroller';
import ActivityIndicator from "../../components/ActivityIndicator";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import moment from 'moment';
import A from "../../components/A";


const Epiloggs = ({}) => {
    const dispatch = useDispatch();

    const [atEnd, setAtEnd] = useState(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [epiloggToDelete, setEpiloggToDelete] = useState({});

    const { epiloggs, isDeletingEpilogg, isGettingEpiloggs, isUndeletingEpilogg } = useSelector(store => store.epiloggs);

    useEffect(() => {
        dispatch(getEpiloggs());
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Unique ID',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'displayName',
            },
            {
                Header: 'Date of Death',
                accessor: 'dateOfDeath',
                Cell: ({row: { original }}) => moment(original.dateOfDeath).format('MMM DD, YYYY')
            },
            {
                Header: 'Status',
                accessor: 'publishStatus'
            },
            {
                Header: '',
                accessor: 'actions',
                Cell: ({row: { original }}) => (
                    <>
                        <Button
                            className="epiloggs__delete-button"
                            onClick={() => toggleDelete(original)}
                        >
                            {original.deleted ? 'Undelete' : 'Delete'}
                        </Button>

                        {!original.deleted && (
                            <A
                                className="epiloggs__delete-button c-button"
                                href={`${process.env.REACT_APP_WEBAPP_URL}/epilogg/view/${original.id}`}
                            >
                                View
                            </A>
                        )}
                    </>
                )
            }
        ],
        []
    );

    const handleDeleteUndelete = async () => {
        try {
            if(epiloggToDelete.deleted) {
                await dispatch(undeleteEpilogg(epiloggToDelete));
            } else {
                await dispatch(deleteEpilogg(epiloggToDelete));
            }
            setShowDeleteConfirmModal(false);
            setEpiloggToDelete({});
        } catch(err) {
            console.log('Epiloggs handleDeleteUndelete err', err);
        }
    };

    const handleInfiniteScroll = async () => {
        if(isGettingEpiloggs || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res = await dispatch(getEpiloggs(true));
            setAtEnd(res.data.data.items.length === 0);
            setIsInfiniteScrolling(false);
        } catch(err) {
            console.log('Epiloggs handleInfiniteScroll err', err);
            setAtEnd(true);
            setIsInfiniteScrolling(false);
        }
    };

    const toggleDelete = (epilogg) => {
        setEpiloggToDelete(epilogg);
        setShowDeleteConfirmModal(true);
    };

    return (
        <div className="epiloggs page-content">

            {isGettingEpiloggs ? (
                <ActivityIndicator/>
            ) : (
                <>
                    {epiloggs && epiloggs.length > 0 ? (
                        <>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={handleInfiniteScroll}
                                hasMore={!atEnd}
                            >
                                <Table
                                    columns={columns}
                                    data={epiloggs}
                                />
                            </InfiniteScroll>

                            {isInfiniteScrolling && (
                                <div className="infinite-scroll-indicator">
                                    <ActivityIndicator/>
                                </div>
                            )}
                        </>
                    ) : (
                        <span>No funeral providers</span>
                    )}
                </>
            )}

            <Modal
                confirmButtonOnClick={handleDeleteUndelete}
                confirmButtonText={epiloggToDelete.deleted ? 'Undelete' : 'Delete'}
                declineButtonOnClick={() => {
                    setShowDeleteConfirmModal(false);
                    setEpiloggToDelete({});
                }}
                declineButtonText="Cancel"
                show={showDeleteConfirmModal}
                showActivityIndicator={isDeletingEpilogg || isUndeletingEpilogg}
                title="Confirm"
            >
                Are you sure you want to {epiloggToDelete.deleted ? 'undelete' : 'delete'} this Epilogg?
            </Modal>
        </div>
    );
};

Epiloggs.propTypes = {

};

export default Epiloggs;
