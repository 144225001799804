import './App.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Redirect, useHistory} from "react-router";
import { Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isMobile } from 'react-device-detect';

import { isAuthPage } from 'utils/utils';
import { LOCALSTORAGE } from 'utils/constants';

import { getLoggedInUser, setToken } from 'containers/Auth/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import Auth from 'containers/Auth';
import Epiloggs from "../Epiloggs";
import Error404 from 'containers/Errors/Error404';
import Errors from 'containers/Errors';
import FuneralProviders from 'containers/FuneralProviders';
import MainFooter from 'components/MainFooter';
import MainHeader from 'components/MainHeader';
import ScrollToTop from './ScrollToTop';

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [hasFetchedProfile, setHasFetchedProfile] = useState(false);

    const { isFetchingProfile, token } = useSelector(store => store.auth);

    useEffect(() => {
        let authToken = localStorage.getItem(LOCALSTORAGE.ID_TOKEN);

        console.log('authtoken', authToken)

        if (authToken == null) {
            if(window.location.pathname === '/') {
                history.push('/auth/login');
            }
            setHasFetchedProfile(true);
        } else {
            dispatch(getLoggedInUser(authToken))
                .then((res) => {
                    dispatch(setToken(authToken));
                    if(isAuthPage(window.location.pathname) || window.location.pathname === '/') {
                        history.push('/providers/all');
                    }
                    setHasFetchedProfile(true);
                })
                .catch((err) => {
                    history.push('/auth/logout');
                    setHasFetchedProfile(true);
                });
        }

        if(isMobile) {
            document.body.classList.add('is-mobile');
        }

        setTimeout(() => document.body.classList.add('loaded'), 200);

        return () => {

        }
    }, [dispatch, history]);

    return (
        <div className="wrapper">
            <ScrollToTop />
            <Helmet
                defaultTitle="epilogg admin"
            />

            {token && <MainHeader />}

            {isFetchingProfile === true || hasFetchedProfile === false ? (
                <div className="main-content wrapper__activity-indicator">
                    <ActivityIndicator />
                </div>
            ) : (
                <div className="main-content">
                    <Switch>
                        <Route path="/auth" component={Auth} />
                        <Route path="/providers" component={FuneralProviders} />
                        <Route path="/epiloggs" component={Epiloggs} />
                        <Route path="/404" component={Error404} />
                        <Redirect path="*" to="/404" />
                    </Switch>
                </div>
            )}

            {token && <MainFooter />}

            <Errors />
        </div>
    );
};

export default App;
