import clone from 'clone';

import * as types from './constants';

const EMPTY_FUNERAL_PROVIDER = {
    locations: []
};

export const initialState = {
    funeralProvider: clone(EMPTY_FUNERAL_PROVIDER),
    funeralProviders: [],
    isGettingFuneralProviders: false,
    getFuneralProvidersError: {},
    isSavingFuneralProvider: false,
    saveFuneralProviderError: {}
};

function funeralProvidersReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_FUNERAL_PROVIDER:
            return { ...state, funeralProvider: clone(EMPTY_FUNERAL_PROVIDER), saveFuneralProviderError: {} };

        case types.GET_FUNERAL_PROVIDERS_START:
            return { ...state, isGettingFuneralProviders: true, getFuneralProvidersError: {} };
        case types.GET_FUNERAL_PROVIDERS_SUCCESS:
            return { ...state, isGettingFuneralProviders: false, funeralProviders: action.data  };
        case types.GET_FUNERAL_PROVIDERS_FAILURE:
            return { ...state, isGettingFuneralProviders: false, getFuneralProvidersError: action.error };

        case types.SAVE_FUNERAL_PROVIDER_START:
            return { ...state, isSavingFuneralProvider: true, saveFuneralProviderError: {} };
        case types.SAVE_FUNERAL_PROVIDER_SUCCESS:
            return { ...state, isSavingFuneralProvider: false, funeralProvider: action.data  };
        case types.SAVE_FUNERAL_PROVIDER_FAILURE:
            return { ...state, isSavingFuneralProvider: false, saveFuneralProviderError: action.error };

        case types.SET_FUNERAL_PROVIDER:
            return { ...state, funeralProvider: action.data };
        default:
            return state;
    }
}

export default funeralProvidersReducer;
