export const LOCALSTORAGE = {
    ID_TOKEN: 'id_token',
    REDIRECT: 'login_redirect',
    CONFIG: 'config'
};

export const MODAL_STYLE_RESET = {
    overlay: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        backgroundColor: null,
    },
    content: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        border: null,
        background: null,
        overflow: null,
        WebkitOverflowScrolling: null,
        borderRadius: null,
        outline: null,
        padding: null,
    },
};

export const breakpoints = {
    largeMobileBreakpoint: '29.5em',
    smallTabletBreakpoint: '36.5em',
    maxTabletBreakpoint: '47.4375em',
    tabletBreakpoint: '47.5em',
    maxDesktopBreakpoint: '62.9375em',
    desktopBreakpoint: '63em',
    widescreenBreakpoint: '80em',
    superWideBreakpoint: '112.5em'
};

export const TYPES = {
    'i': ['image'],
    'ii': ['image', 'image'],
    'iii': ['image', 'image', 'image'],
    't': ['text'],
    'tt': ['text', 'text'],
    'ttt': ['text', 'text', 'text'],
    'ti': ['text', 'image'],
    'it': ['image', 'text'],
    't23i': ['text', 'image'],
    'it23': ['image', 'text'],
    'd': ['donation'],
    'f': ['family'],
    'g': ['guestbook'],
    'm': ['memorial'],
    'flower': ['flower']
};

/*
w600
w1200
s520
s400
 */

export const IMAGE_SIZES = {
    AVATAR: '/s520/',
    BANNER: '/w1200/',
    PICTURE: '/s520/'
};

export const PROFILE_MODAL_NAV_OPTIONS = [
    {
        label: 'Profile / Settings',
        tab: 'profile'
    }, {
        label: 'Change Password',
        tab: 'security'
    }
];
