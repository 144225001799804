import './Auth.scss';

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import ForgotPassword from 'containers/Auth/ForgotPassword';
import Login from 'containers/Auth/Login';
import Logout from 'containers/Auth/Logout';
import ResetPassword from 'containers/Auth/ResetPassword';

const Auth = () => {
    useEffect(() => {
        document.body.classList.add('auth-page');

        return () => {
            document.body.classList.remove('auth-page');
        }
    }, []);

    return (
        <div className="auth-form--wrapper">
            <div className="auth-form__content">
                <Switch>
                    <Route path="/auth/forgot-password" component={ForgotPassword} />
                    <Route path="/auth/login" component={Login} />
                    <Route path="/auth/logout" component={Logout} />
                    <Route path="/auth/reset-password" component={ResetPassword} />
                </Switch>
            </div>
        </div>
    );
};

export default Auth;
