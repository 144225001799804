import clone from 'clone';

import * as types from './constants';

export const initialState = {
    epilogg: {},
    epiloggs: [],
    epiloggsMetadata: {page: 0},
    isDeletingEpilogg: false,
    deleteEpiloggError: {},
    isGettingEpiloggs: false,
    getEpiloggsError: {},
    isUndeletingEpilogg: false,
    undeleteEpiloggError: {}
};

function epiloggsReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_EPILOGG:
            return { ...state, epilogg: {} };

        case types.DELETE_EPILOGG_START:
            return { ...state, isDeletingEpilogg: true, deleteEpiloggError: {} };
        case types.DELETE_EPILOGG_SUCCESS:
            console.log(action.data);
            if(action.data) {
                let epiloggs = clone(state.epiloggs);

                let foundIndex = epiloggs.findIndex((epilogg) => epilogg.id === action.data.id);
                console.log(foundIndex);
                epiloggs[foundIndex].deleted = !epiloggs[foundIndex].deleted;

                return { ...state, isDeletingEpilogg: false, epiloggs };
            }

            return state;
        case types.DELETE_EPILOGG_FAILURE:
            return { ...state, isDeletingEpilogg: false, deleteEpiloggError: action.error };

        case types.GET_EPILOGGS_START:
            return { ...state, isGettingEpiloggs: action.hideSpinner !== true, getEpiloggsError: {} };
        case types.GET_EPILOGGS_SUCCESS:
            return { ...state, isGettingEpiloggs: false, epiloggs: action.data, epiloggsMetadata: action.metadata  };
        case types.GET_EPILOGGS_FAILURE:
            return { ...state, isGettingEpiloggs: false, getEpiloggsError: action.error };

        case types.SET_EPILOGG:
            return { ...state, epilogg: action.data };

        case types.UNDELETE_EPILOGG_START:
            return { ...state, isUndeletingEpilogg: true, undeleteEpiloggError: {} };
        case types.UNDELETE_EPILOGG_SUCCESS:
            console.log(action.data);
            if(action.data) {
                let epiloggs = clone(state.epiloggs);

                let foundIndex = epiloggs.findIndex((epilogg) => epilogg.id === action.data.id);
                console.log(foundIndex);
                epiloggs[foundIndex].deleted = !epiloggs[foundIndex].deleted;

                return { ...state, isUndeletingEpilogg: false, epiloggs };
            }

            return state;
        case types.UNDELETE_EPILOGG_FAILURE:
            return { ...state, isUndeletingEpilogg: false, undeleteEpiloggError: action.error };

        default:
            return state;
    }
}

export default epiloggsReducer;
