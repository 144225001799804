export const CLEAR_CREDENTIALS = 'Auth/CLEAR_CREDENTIALS';
export const CLEAR_FORGOT_PASSWORD_DATA = 'Auth/CLEAR_FORGOT_PASSWORD_DATA';
export const CLEAR_LOGIN_ERROR = 'Auth/CLEAR_LOGIN_ERROR';

export const GET_LOGGED_IN_USER_START = 'Auth/GET_LOGGED_IN_USER_START';
export const GET_LOGGED_IN_USER_SUCCESS = 'Auth/GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_FAILURE = 'Auth/GET_LOGGED_IN_USER_FAILURE';

export const LOGIN_START = 'Auth/LOGIN_START';
export const LOGIN_SUCCESS = 'Auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'Auth/LOGIN_FAILURE';

export const RESET_PASSWORD_START = 'Auth/RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'Auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'Auth/RESET_PASSWORD_FAILURE';

export const SEND_FORGOT_PASSWORD_EMAIL_START = 'Auth/SEND_FORGOT_PASSWORD_EMAIL_START';
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'Auth/SEND_FORGOT_PASSWORD_EMAIL_SUCCESS';
export const SEND_FORGOT_PASSWORD_EMAIL_FAILURE = 'Auth/SEND_FORGOT_PASSWORD_EMAIL_FAILURE';

export const SET_CREDENTIALS = 'Auth/SET_CREDENTIALS';
export const SET_PROFILE = 'Auth/SET_PROFILE';
export const SET_TOKEN = 'Auth/SET_TOKEN';

export const UPDATE_AUTH = 'Auth/UPDATE_AUTH';

export const RESET_STORE = 'Auth/RESET_STORE';
