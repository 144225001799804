import * as types from './constants';
import Request from "../../../utils/request";
import PATHS from "../../../utils/paths";
import {generateFriendlyErrorString} from "../../../utils/data";
import {addError} from "../../Errors/store/actions";

export const clearFuneralProvider = () => ({
    type: types.CLEAR_FUNERAL_PROVIDER
});

const getFuneralProvidersStart = () => ({
    type: types.GET_FUNERAL_PROVIDERS_START
});

const getFuneralProvidersSuccess = (data) => ({
    type: types.GET_FUNERAL_PROVIDERS_SUCCESS,
    data
});

const getFuneralProvidersFailure = (error) => ({
    type: types.GET_FUNERAL_PROVIDERS_FAILURE,
    error
});

const saveFuneralProviderStart = () => ({
    type: types.SAVE_FUNERAL_PROVIDER_START
});

const saveFuneralProviderSuccess = (data) => ({
    type: types.SAVE_FUNERAL_PROVIDER_SUCCESS,
    data
});

const saveFuneralProviderFailure = (error) => ({
    type: types.SAVE_FUNERAL_PROVIDER_FAILURE,
    error
});

export const setFuneralProvider = (data) => ({
    type: types.SET_FUNERAL_PROVIDER,
    data
});

export const getFuneralProviders = () => (dispatch, getStore) => {
    dispatch(getFuneralProvidersStart());

    const request = new Request(getStore().auth.token);

    return request.get(PATHS.funeralProviders.getAll())
        .then(response => {
            dispatch(getFuneralProvidersSuccess(response.data.data));
            return response;
        })
        .catch(err => {
            console.log('getFuneralProviders error', err);
            err.friendlyMessage = generateFriendlyErrorString('Error getting the list of funeral providers. Please refresh the page.', err.response.data);
            dispatch(getFuneralProvidersFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const saveFuneralProvider = () => (dispatch, getStore) => {
    dispatch(saveFuneralProviderStart());

    const funeralProvider = getStore().funeralProviders.funeralProvider;

    const request = new Request(getStore().auth.token);

    let path = PATHS.funeralProviders.create();
    let reqFunc = request.post;

    if(funeralProvider.id) {
        path = PATHS.funeralProviders.save(funeralProvider.id);
        reqFunc = request.put;
    }


    return reqFunc(path, funeralProvider)
        .then(response => {
            dispatch(saveFuneralProviderSuccess(response.data.data));
            return response;
        })
        .catch(err => {
            console.log('saveFuneralProvider error', err);
            if(err.response.status === 400) {
                err.friendlyMessage = generateFriendlyErrorString('Location already saved to another provider. Please remove it and save again.', err.response.data);
            } else {
                err.friendlyMessage = generateFriendlyErrorString('Error saving this funeral provider. Please try again.', err.response.data);
            }
            dispatch(saveFuneralProviderFailure(err));
            dispatch(addError(err));
            throw err;
        });
};
