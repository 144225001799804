import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from "react-router";

import FuneralProvidersCreateEdit from './CreateEdit';
import FuneralProvidersList from './List';

const FuneralProviders = ({navigation}) => {
    return (
        <Switch>
            <Route path="/providers/all" component={FuneralProvidersList} />
            <Route path="/providers/create" component={FuneralProvidersCreateEdit} />
            <Route path="/providers/edit/:id" component={FuneralProvidersCreateEdit} />
        </Switch>
    );
};

export default FuneralProviders;
