import clone from 'clone';

import Request from 'utils/request';
import PATHS from 'utils/paths';

import { LOCALSTORAGE } from 'utils/constants';
import { generateFriendlyErrorString } from 'utils/data';

import { addError } from 'containers/Errors/store/actions';

import * as types from './constants';

export const clearCredentials = () => ({
    type: types.CLEAR_CREDENTIALS,
});

export const clearForgotPasswordData = () => ({
    type: types.CLEAR_FORGOT_PASSWORD_DATA,
});

export const clearLoginError = () => ({
    type: types.CLEAR_LOGIN_ERROR,
});

const getLoggedInUserStart = () => ({
    type: types.GET_LOGGED_IN_USER_START,
});

const getLoggedInUserSuccess = (data) => ({
    type: types.GET_LOGGED_IN_USER_SUCCESS,
    data
});

const getLoggedInUserFailure = error => ({
    type: types.GET_LOGGED_IN_USER_FAILURE,
    error,
});

const loginStart = () => ({
    type: types.LOGIN_START,
});

const loginSuccess = (profile, token) => ({
    type: types.LOGIN_SUCCESS,
    profile,
    token
});

const loginFailure = error => ({
    type: types.LOGIN_FAILURE,
    error,
});

const resetPasswordStart = () => ({
    type: types.RESET_PASSWORD_START,
});

const resetPasswordSuccess = () => ({
    type: types.RESET_PASSWORD_SUCCESS,
});

const resetPasswordFailure = error => ({
    type: types.RESET_PASSWORD_FAILURE,
    error,
});

const sendForgotPasswordEmailStart = () => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_START,
});

const sendForgotPasswordEmailSuccess = () => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
});

const sendForgotPasswordEmailFailure = error => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
    error,
});

export const setCredentials = data => ({
    type: types.SET_CREDENTIALS,
    data,
});

export const setProfile = data => ({
    type: types.SET_PROFILE,
    data,
});

export const setToken = data => ({
    type: types.SET_TOKEN,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const getLoggedInUser = token => (dispatch, getStore) => {
    dispatch(getLoggedInUserStart());

    if (token == null) {
        token = getStore().auth.token;
    }

    const request = new Request(token);

    return request.get(PATHS.auth.getLoggedInUser())
        .then(response => {
            dispatch(getLoggedInUserSuccess(response.data.data.user));
            return response;
        })
        .catch(err => {
            console.log('getLoggedInUserError', err);
            err.friendlyMessage = generateFriendlyErrorString('Error getting your information. Please log in again.', err.response.data);
            dispatch(getLoggedInUserFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const login = () => (dispatch, getStore) => {
    dispatch(loginStart());

    let credentials = getStore().auth.credentials;

    const request = new Request();

    return request.post(PATHS.auth.login(), credentials)
        .then(response => {
            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, response.data.data.token);
            dispatch(loginSuccess(response.data.data.user, response.data.data.token));
            return response;
        })
        .catch(err => {
            console.log(err);
            if(err.response.status === 401) {
                err.friendlyMessage = generateFriendlyErrorString('Incorrect username or password. Please try again.', err.response.data);
            } else {
                err.friendlyMessage = generateFriendlyErrorString('There was an error when trying to log in. Please try again.', err.response.data);
            }

            dispatch(loginFailure(err));
            throw err;
        });
};

export const resetPassword = token => (dispatch, getStore) => {
    dispatch(resetPasswordStart());

    let password = getStore().auth.credentials.password;

    const request = new Request();

    return request.post(PATHS.auth.resetPassword(), {password, token})
        .then(response => {
            dispatch(resetPasswordSuccess());
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('Unable to reset password. Please try again.', err.response.data);
            dispatch(resetPasswordFailure(err));
            throw err;
        });
};

export const sendForgotPasswordEmail = () => (dispatch, getStore) => {
    dispatch(sendForgotPasswordEmailStart());

    let email = getStore().auth.credentials.email;

    const request = new Request();

    return request.post(PATHS.auth.forgotPassword(), {email})
        .then(response => {
            dispatch(sendForgotPasswordEmailSuccess());
            return response;
        })
        .catch(err => {
            console.log(err.response);
            err.friendlyMessage = generateFriendlyErrorString('Unable to reset password. Please try again.', err.response.data);
            dispatch(sendForgotPasswordEmailFailure(err));
            throw err;
        });
};
