import './Logo.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({className}) => (
    <div className={`c-logo ${className || ''}`}>
        <svg viewBox="0 0 240.19 49.72">
            <path fill="#414042"
                  d="M51.67,18.59a8.69,8.69,0,0,0-6.5-2.49A10.64,10.64,0,0,0,34.64,26.82a11.15,11.15,0,0,0,5.48,9.57,10.32,10.32,0,0,0,5.34,1.34c3.59,0,6-1,7.64-3.22A9.73,9.73,0,0,0,54.81,30l0-.22H52.19l0,.14c-1,3.69-2.87,5.19-6.46,5.19s-5.9-2.55-6.44-7.39H54.91v-.61C54.91,23.14,54,20.66,51.67,18.59Zm-12.38,7c.41-4.56,2.41-7,5.79-7,3.6,0,5.66,2.42,5.66,6.65v.32Z"/>
            <path fill="#414042"
                  d="M75.64,15.87A7.58,7.58,0,0,0,69,19.27V16.62H61.13V19.1h2.66c.89,0,1.37.35,1.37,1.92V42.73c0,.87-.48,1.32-1.45,1.36H61v2.44H74.1V44.09H70.87c-1.54,0-1.78-.39-1.83-1.54l0-7.76,1.27,1c.33.25.57.44.63.5a7.58,7.58,0,0,0,4.61,1.34c5.59,0,10-4.83,10-11S81.3,15.87,75.64,15.87Zm5.34,11c0,3.56-.89,5.9-2.74,7.15A5.43,5.43,0,0,1,75,35c-3.7,0-6-3.17-6-8.28,0-4.93,2.38-8.24,5.94-8.24C78.66,18.49,81,21.68,81,26.82Z"/>
            <path fill="#414042"
                  d="M100.68,32.67V16.81H92.54v2.43h2.24c1.73,0,1.83.14,1.88,1.73V32.39c0,2-.28,2.21-1.41,2.25H92.54v2.53h12.12V34.64h-2.61C100.9,34.6,100.73,34.47,100.68,32.67Z"/>
            <path fill="#414042"
                  d="M98.53,11.38a2.78,2.78,0,0,0,2.85-2.9,2.93,2.93,0,0,0-2.9-2.95,3,3,0,0,0-2.9,3A2.81,2.81,0,0,0,98.53,11.38Z"/>
            <path fill="#414042"
                  d="M118.93,33.05V5.57h-8.09V8.06h2.65c1.11.09,1.47.47,1.47,1.55V33.37c0,1-.33,1.32-1.45,1.36h-2.67v2.44h12.21V34.73h-2.38C119.24,34.69,119,34.44,118.93,33.05Z"/>
            <path fill="#414042"
                  d="M140,16.1A10.74,10.74,0,0,0,129.23,27a10.58,10.58,0,1,0,21.16.19C150.39,20.87,145.94,16.1,140,16.1Zm5.81,10.77c0,5.43-2.15,8.42-6,8.42s-6-2.94-6-8.28,2.14-8.38,6.18-8.38C143.53,18.63,145.85,21.86,145.85,26.87Z"/>
            <path fill="#414042"
                  d="M174.2,15a2,2,0,0,0,2.16,1.68,2.05,2.05,0,0,0,2.06-2.15,2.74,2.74,0,0,0-2.9-2.76c-2.64,0-4.21,1.94-4.21,5.19v.29a9.36,9.36,0,0,0-5.2-1.27c-5,0-8.56,2.83-8.56,6.88a5.53,5.53,0,0,0,3.23,5.28c-2.69,1.31-3.61,2.45-3.61,4.41a4,4,0,0,0,.84,2.6,4.86,4.86,0,0,0,1.8,1.12,11.1,11.1,0,0,0-2,1.37,4.09,4.09,0,0,0-1.48,3.19c0,3.69,4.33,6.37,10.3,6.37,6.69,0,11.37-3.14,11.37-7.63A6,6,0,0,0,174.09,34c-1.43-.54-2.81-.67-7.37-.67a30.37,30.37,0,0,1-4.92-.18c-1-.22-1.4-.71-1.4-1.55,0-1.24,1.26-2,3.28-2h.55c2.34.14,2.34.14,2.72.14,4.9,0,7.82-2.59,7.82-6.93a6.55,6.55,0,0,0-.67-3.07,8.82,8.82,0,0,1-.73-3.15A1.47,1.47,0,0,1,174.2,15ZM159.65,40.77a3.16,3.16,0,0,1,1.68-2.64h0c1.3-.68,2.88-.87,7-.87,2.75,0,3.7.14,4.87.73a2.58,2.58,0,0,1,1.35,2.26c0,2.69-3.23,4.64-7.67,4.64S159.65,43.31,159.65,40.77Zm11-17.88c0,3-1.52,4.63-4.26,4.63-3,0-4.73-1.77-4.73-4.86a4.19,4.19,0,0,1,4.49-4.54C169,18.12,170.7,20,170.7,22.89Z"/>
            <path fill="#414042"
                  d="M201.61,34c-1.43-.54-2.81-.67-7.37-.67a30.37,30.37,0,0,1-4.92-.18c-.95-.22-1.4-.71-1.4-1.55,0-1.24,1.26-2,3.28-2h.55c2.34.14,2.34.14,2.72.14,4.9,0,7.82-2.59,7.82-6.93a6.55,6.55,0,0,0-.67-3.07,8.82,8.82,0,0,1-.73-3.15,1.47,1.47,0,0,1,.83-1.5,2,2,0,0,0,2.16,1.68,2.06,2.06,0,0,0,2.06-2.15A2.74,2.74,0,0,0,203,11.8c-2.64,0-4.21,1.94-4.21,5.19v.29a9.36,9.36,0,0,0-5.2-1.27c-5,0-8.56,2.83-8.56,6.88a5.53,5.53,0,0,0,3.23,5.28c-2.69,1.31-3.61,2.45-3.61,4.41a4,4,0,0,0,.84,2.6,4.86,4.86,0,0,0,1.8,1.12,11.69,11.69,0,0,0-2,1.37,4.12,4.12,0,0,0-1.47,3.19c0,3.69,4.33,6.37,10.3,6.37,6.69,0,11.37-3.14,11.37-7.63A6,6,0,0,0,201.61,34ZM189.23,22.66a4.19,4.19,0,0,1,4.49-4.54c2.78,0,4.5,1.83,4.5,4.77s-1.52,4.63-4.26,4.63C191,27.52,189.23,25.75,189.23,22.66ZM202.1,40.25c0,2.69-3.23,4.64-7.67,4.64s-7.26-1.58-7.26-4.12a3.16,3.16,0,0,1,1.68-2.64h0c1.3-.68,2.88-.87,7-.87,2.74,0,3.7.14,4.87.73A2.58,2.58,0,0,1,202.1,40.25Z"/>
            <path fill="#6bcdb2"
                  d="M236.69,17.76V7.67C236.69,2,234.85,0,229.4,0h-.14V.7a6.51,6.51,0,0,1,4.47,1.53c1.29,1.32,1.51,3.3,1.44,6.08V18c0,3.41.73,4.89,3,6.08l1.57.82-1.59.8c-2.18,1.09-2.87,2.05-3,6V41.7c.07,2.52-.15,4.47-1.42,5.78A6.59,6.59,0,0,1,229.26,49v.68h0c5.47,0,7.41-1.86,7.41-7.1V31.91c0-3.23.6-5.64,3.5-6.8v-.5C237.29,23.39,236.69,21,236.69,17.76Z"/>
            <path fill="#6bcdb2"
                  d="M3.51,17.76V7.67C3.51,2,5.35,0,10.79,0h.14V.7A6.52,6.52,0,0,0,6.46,2.23C5.17,3.55,5,5.53,5,8.31V18c0,3.41-.73,4.89-3,6.08l-1.58.82,1.6.8c2.17,1.09,2.86,2.05,3,6V41.7c-.06,2.52.15,4.47,1.43,5.78A6.56,6.56,0,0,0,10.93,49v.68h0c-5.46,0-7.4-1.86-7.4-7.1V31.91c0-3.23-.61-5.64-3.51-6.8v-.5C2.9,23.39,3.51,21,3.51,17.76Z"/>
        </svg>
    </div>
);

Logo.propTypes = {
    className: PropTypes.string
};

export default Logo;
