import './FuneralProvidersCreateEdit.scss';

import React from 'react';
import {useSelector} from "react-redux";

import FuneralProvidersCreateEditForm from './FuneralProvidersCreateEditForm';

const FuneralProvidersCreateEdit = ({navigation}) => {
    const { funeralProvider } = useSelector(store => store.funeralProviders);



    return (
        <div className="funeral-providers page-content">
            <FuneralProvidersCreateEditForm
                funeralProvider={funeralProvider}
                navigation={navigation}
            />
        </div>
    );
};

export default FuneralProvidersCreateEdit;
