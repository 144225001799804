import './MainFooter.scss';

import React from 'react';

const MainFooter = () => {
    return (
        <footer className="c-main-footer">
            <div className="c-main-footer__copyright">
                © {new Date().getFullYear()} Epilogg All Rights Reserved.
            </div>
        </footer>
    );
};

export default MainFooter;
