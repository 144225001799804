const PATHS = {
    auth: {
        forgotPassword: () => `v1/password/forgot`,
        getLoggedInUser: () => `v1/me`,
        login: () => `v1/login`,
        resetPassword: () => `v1/password/reset`
    },
    epiloggs: {
        delete: (id) => `v1/epilogg/${id}`,
        get: (page) => `v1/epilogg?page_num=${page}&page_size=30`,
        undelete: (id) => `v1/epilogg/${id}/undelete`
    },
    funeralProviders: {
        create: () => `v1/funeral_provider`,
        getAll: () => `v1/funeral_provider`,
        save: (id) => `v1/funeral_provider/${id}`
    },
    upload: {
        getPresignedUrl: () => 'v1/upload/photos'
    }
};

export default PATHS;
