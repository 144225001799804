import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmail, isRequired } from "../../../utils/validations";
import {validated} from "react-custom-validation";
import Form from "../../../components/Form";
import FormRow from "../../../components/FormRow";
import Textbox from "../../../components/Textbox";
import {handleTextChange} from "../../../utils/handle-changes";
import clone from "clone";
import {clearFuneralProvider, saveFuneralProvider, setFuneralProvider} from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import FormError from "../../../components/FormError";
import Autocomplete from 'react-google-autocomplete';
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import FormColumn from "../../../components/FormColumn";
import Map from "../../../components/Map";
import { useHistory } from "react-router";

let FuneralProvidersCreateEditForm = ({funeralProvider, navigation, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const locationRef = useRef();

    const { isSavingFuneralProvider, saveFuneralProviderError } = useSelector(store => store.funeralProviders);

    const handleCancel = (event) => {
        event.preventDefault();
        dispatch(clearFuneralProvider());
        $fieldEvent('reset');
        history.push('/providers/create');
    };

    const handleChange = (name, value) => {
        let newFuneralProviderObj = clone(funeralProvider);
        newFuneralProviderObj[name] = value;
        dispatch(setFuneralProvider(newFuneralProviderObj));
    };

    const handlePlaceSelected = (place) => {
        console.log('place', place, locationRef.current.input);
        let newFuneralProviderObj = clone(funeralProvider);

        let foundIndex = -1;
        if(funeralProvider.locations && funeralProvider.locations.length > 0) {
            foundIndex = funeralProvider.locations.findIndex((l) => l.googlePlacesId === place.place_id);
        }

        if(foundIndex === -1) {
            let newLocation = {
                locationLatitude: place.geometry.location.lat(),
                locationLongitude: place.geometry.location.lng(),
                address: place.formatted_address,
                name: place.name,
                website: place.website,
                phoneNumber: place.formatted_phone_number,
                googlePlacesId: place.place_id
            };
            newFuneralProviderObj.locations.push(newLocation);
        } else {
            newFuneralProviderObj.locations.splice(foundIndex, 1);
        }

        dispatch(setFuneralProvider(newFuneralProviderObj));
        if(locationRef.current && locationRef.current.refs && locationRef.current.refs.input) {
            locationRef.current.refs.input.value = '';
        }
    };

    const handleRemoveLocation = (location) => {
        const foundIndex = funeralProvider.locations.findIndex((l) => l.googlePlacesId === location.googlePlacesId);
        if(foundIndex !== -1) {
            let newFuneralProviderObj = clone(funeralProvider);
            newFuneralProviderObj.locations.splice(foundIndex, 1);
            dispatch(setFuneralProvider(newFuneralProviderObj));
        }
    };

    const handleSubmit = async (isValid) => {
        if(isValid) {
            try {
                await dispatch(saveFuneralProvider());
                dispatch(clearFuneralProvider());
                $fieldEvent('reset');
            } catch(err) {}
        }
    };

    return (
        <Form>
            <FormRow columnCount={2}>
                <FormColumn>
                    <Textbox
                        id="txtName"
                        label="provider name"
                        name="name"
                        placeholder="provider name"
                        required
                        type="text"
                        validation={$validation.name}
                        value={funeralProvider.name || ''}
                        {...$field('name', (event) => handleTextChange(handleChange, event))}
                    />
                </FormColumn>
            </FormRow>

            <FormRow columnCount={2}>
                <FormColumn>
                    <Textbox
                        id="txtEmailAddress"
                        label="provider email"
                        name="contactEmail"
                        placeholder="provider email"
                        required
                        type="email"
                        validation={$validation.contactEmail}
                        value={funeralProvider.contactEmail || ''}
                        {...$field('contactEmail', (event) => handleTextChange(handleChange, event))}
                    />
                </FormColumn>
            </FormRow>

            <FormRow columnCount={2}>
                <FormColumn>
                    <div className="o-textbox funeral-providers-create__autocomplete">
                        <Autocomplete
                            className="c-textbox"
                            fields={[
                                'geometry.location',
                                'formatted_address',
                                'name',
                                'website',
                                'formatted_phone_number',
                                'place_id'
                            ]}
                            onPlaceSelected={(place) => {
                                handlePlaceSelected(place);
                            }}
                            placeholder="search for locations"
                            ref={locationRef}
                            types={['establishment']}
                        />
                    </div>

                    {funeralProvider.locations && funeralProvider.locations.length > 0 && (
                        <div className="funeral-providers-create__locations">
                            {funeralProvider.locations.map((l) => (
                                <div className="funeral-providers-create__location">
                                    <div className="funeral-providers-create__location-details">
                                        <div className="funeral-providers-create__location-name">
                                            {l.name}
                                        </div>

                                        {l.address}
                                    </div>

                                    <Button
                                        noStyles
                                        onClick={() => handleRemoveLocation(l)}
                                    >
                                        <Icon type="x" />
                                    </Button>
                                </div>
                            ))}
                        </div>
                    )}
                </FormColumn>

                {funeralProvider.locations && funeralProvider.locations.length > 0 && (
                    <FormColumn>
                        <Map
                            locations={funeralProvider.locations}
                        />
                    </FormColumn>
                )}
            </FormRow>

            <FormError
                error={saveFuneralProviderError}
            />

            <FormRow columnCount={2}>
                <FormColumn className="funeral-providers-create__buttons">
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleSubmit(true), () => handleSubmit(false));
                        }}
                        showActivityIndicator={isSavingFuneralProvider}
                    >
                        Save
                    </Button>

                    <Button
                        disabled={isSavingFuneralProvider}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </FormColumn>
            </FormRow>
        </Form>
    );
};

FuneralProvidersCreateEditForm.propTypes = {
    funeralProvider: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired
};

function FuneralProvidersCreateEditFormValidationConfig(props) {
    const { contactEmail, name } = props.funeralProvider;

    return {
        fields: ['contactEmail', 'name'],
        validations: {
            contactEmail: [
                [isRequired, contactEmail],
                [isEmail, contactEmail]
            ],
            name: [
                [isRequired, name]
            ]
        }
    }
}


export default FuneralProvidersCreateEditForm = validated(FuneralProvidersCreateEditFormValidationConfig)(FuneralProvidersCreateEditForm);
