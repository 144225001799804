import './MainHeader.scss';

import React from 'react';
import MediaQuery from 'react-responsive';

import { breakpoints } from 'utils/constants';

import A from 'components/A';
import Button from 'components/Button';
import Logo from 'components/Logo';
import MainNav from './MainNav';

const MainHeader = () => {

    const openCloseMenu = () => {
        document.body.classList.toggle('open-menu');
    };

    return (
        <header className="c-main-header">
            <div className="c-main-header--inner">
                <A href={process.env.REACT_APP_WEBSITE_URL}>
                    <Logo />
                </A>

                <div className="c-main-header__nav-wrapper">
                    <div className="c-main-header__quick-links">

                        <MediaQuery maxWidth={breakpoints.maxTabletBreakpoint}>
                            {(matches) => {
                                if (matches) {
                                    return (
                                        <Button className="open-close-menu"
                                                onClick={openCloseMenu}>
                                            <div className="open-close-menu_inner">
                                                <div className="menu-line line1" />
                                                <div className="menu-line line2">
                                                    <div className="menu-line line4" />
                                                </div>
                                                <div className="menu-line line3" />
                                            </div>
                                        </Button>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                        </MediaQuery>
                    </div>

                    <MainNav />
                </div>
            </div>
        </header>
    );
};

MainHeader.propTypes = {

};

export default MainHeader;
