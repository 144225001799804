import './Map.scss';

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker } from "@react-google-maps/api";

const Map = ({locations}) => {
    const [cachedLocations, setCachedLocations] = useState([]);
    const [mapCenter, setMapCenter] = useState({lat: 0, lng: 0});
    const [mapZoom, setMapZoom] = useState(14);
    const [map, setMap] = useState(null);

    useEffect(() => {
        console.log('locations changed');
        if(locations.length === 1) {
            setMapZoom(14);
            setMapCenter({lat: locations[0].locationLatitude, lng: locations[0].locationLongitude});
        } else {
            let bounds = new window.google.maps.LatLngBounds();
            locations.forEach((l) => {
                const loc = new window.google.maps.LatLng(l.locationLatitude, l.locationLongitude);
                bounds.extend(loc)
            });
            if(map) {
                map.fitBounds(bounds);
                map.panToBounds(bounds);
            }
        }

        setCachedLocations(locations);
    }, [locations, setCachedLocations]);

    const onLoad = useCallback(function callback(map) {
        console.log('onload');
        //const bounds = new window.google.maps.LatLngBounds();

        //map.fitBounds(bounds);
        setMap(map)
    }, []);

    return (
        <div className="c-map">
            <GoogleMap
                center={mapCenter}
                mapContainerStyle={{position: 'absolute', height: '100%', width: '100%'}}
                onLoad={onLoad}
                zoom={mapZoom}
            >
                {cachedLocations && cachedLocations.map((l) => (
                    <Marker
                        position={{lat: l.locationLatitude, lng: l.locationLongitude}}
                    />
                ))}
            </GoogleMap>
        </div>
    );
};

Map.propTypes = {
    locations: PropTypes.array.isRequired
};

export default Map;
