export const CLEAR_FUNERAL_PROVIDER = 'FuneralProviders/CLEAR_FUNERAL_PROVIDER';

export const GET_FUNERAL_PROVIDERS_START = 'FuneralProviders/GET_FUNERAL_PROVIDERS_START';
export const GET_FUNERAL_PROVIDERS_SUCCESS = 'FuneralProviders/GET_FUNERAL_PROVIDERS_SUCCESS';
export const GET_FUNERAL_PROVIDERS_FAILURE = 'FuneralProviders/GET_FUNERAL_PROVIDERS_FAILURE';

export const SAVE_FUNERAL_PROVIDER_START = 'FuneralProviders/SAVE_FUNERAL_PROVIDER_START';
export const SAVE_FUNERAL_PROVIDER_SUCCESS = 'FuneralProviders/SAVE_FUNERAL_PROVIDER_SUCCESS';
export const SAVE_FUNERAL_PROVIDER_FAILURE = 'FuneralProviders/SAVE_FUNERAL_PROVIDER_FAILURE';

export const SET_FUNERAL_PROVIDER = 'FuneralProviders/SET_FUNERAL_PROVIDER';
