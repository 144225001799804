import './FuneralProvidersList.scss';

import React, { useEffect, useMemo, useState } from 'react';
import {getFuneralProviders, setFuneralProvider} from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import ActivityIndicator from "../../../components/ActivityIndicator";
import { useTable } from "react-table";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import { useHistory } from 'react-router';
import Table from "../../../components/Table";

const FuneralProvidersList = ({navigation}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [loaded, setLoaded] = useState(false);

    const { funeralProviders, isGettingFuneralProviders } = useSelector(store => store.funeralProviders);

    useEffect(() => {
        initialize();
    }, []);

    const handleEdit = (provider) => {
        dispatch(setFuneralProvider(provider));
        history.push(`/providers/edit/${provider.id}`);
    };

    const initialize = async () => {
        try {
            await dispatch(getFuneralProviders());
            setLoaded(true);
        } catch(err) {}
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Unique ID',
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Email Address',
                accessor: 'contactEmail',
            },
            {
                Header: '',
                accessor: 'actions',
                Cell: ({row: { original }}) => (
                    <Button
                        className="funeral-providers__edit-button"
                        noStyles
                        onClick={() => handleEdit(original)}
                    >
                        <Icon type="edit" />
                    </Button>
                )
            }
        ],
        []
    );

    return (
        <div className="funeral-providers page-content">
            {isGettingFuneralProviders || !loaded ? (
                <ActivityIndicator />
            ) : (
                <div className="funeral-providers__list-wrapper">
                    {funeralProviders && funeralProviders.length > 0 ? (
                        <Table
                            columns={columns}
                            data={funeralProviders}
                        />
                    ) : (
                        <span>No funeral providers</span>
                    )}
                </div>
            )}
        </div>
    );
};

export default FuneralProvidersList;
