export const CLEAR_EPILOGG = 'Epiloggs/CLEAR_EPILOGG';

export const DELETE_EPILOGG_START = 'Epiloggs/DELETE_EPILOGG_START';
export const DELETE_EPILOGG_SUCCESS = 'Epiloggs/DELETE_EPILOGG_SUCCESS';
export const DELETE_EPILOGG_FAILURE = 'Epiloggs/DELETE_EPILOGG_FAILURE';

export const GET_EPILOGGS_START = 'Epiloggs/GET_EPILOGGS_START';
export const GET_EPILOGGS_SUCCESS = 'Epiloggs/GET_EPILOGGS_SUCCESS';
export const GET_EPILOGGS_FAILURE = 'Epiloggs/GET_EPILOGGS_FAILURE';

export const SET_EPILOGG = 'Epiloggs/SET_EPILOGG';

export const UNDELETE_EPILOGG_START = 'Epiloggs/UNDELETE_EPILOGG_START';
export const UNDELETE_EPILOGG_SUCCESS = 'Epiloggs/UNDELETE_EPILOGG_SUCCESS';
export const UNDELETE_EPILOGG_FAILURE = 'Epiloggs/UNDELETE_EPILOGG_FAILURE';
