import clone from 'clone';

import * as types from './constants';

const EMPTY_CREDENTIALS = {
    email: '',
    password: '',
};

export const initialState = {
    credentials: clone(EMPTY_CREDENTIALS),
    profile: {},
    token: null,
    isFetchingProfile: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isResettingPassword: false,
    isSendingForgotPasswordEmail: false,
    loginError: {},
    loggedInUserError: {},
    logoutError: {},
    resetPasswordError: {},
    sendForgotPasswordEmailError: {}
};

function authReducer(state = clone(initialState), action) {
    switch (action.type) {
        case types.CLEAR_CREDENTIALS:
            return { ...state, credentials: clone(EMPTY_CREDENTIALS) };
        case types.CLEAR_FORGOT_PASSWORD_DATA:
            return { ...state, forgotPasswordData: {} };
        case types.CLEAR_LOGIN_ERROR:
            return { ...state, loginError: {} };

        case types.GET_LOGGED_IN_USER_START:
            return { ...state, isGettingLoggedInUser: true, loggedInUserError: {} };
        case types.GET_LOGGED_IN_USER_SUCCESS:
            return { ...state, isGettingLoggedInUser: false, profile: action.data  };
        case types.GET_LOGGED_IN_USER_FAILURE:
            return { ...state, isGettingLoggedInUser: false, loggedInUserError: action.error };

        case types.LOGIN_START:
            return { ...state, isLoggingIn: true, loginError: {} };
        case types.LOGIN_SUCCESS:
            return { ...state, isLoggingIn: false, profile: action.profile, token: action.token };
        case types.LOGIN_FAILURE:
            return { ...state, isLoggingIn: false, loginError: action.error };

        case types.RESET_PASSWORD_START:
            return { ...state, isResettingPassword: true, resetPasswordError: {} };
        case types.RESET_PASSWORD_SUCCESS:
            return { ...state, isResettingPassword: false, forgotPasswordData: {} };
        case types.RESET_PASSWORD_FAILURE:
            return { ...state, isResettingPassword: false, resetPasswordError: action.error };

        case types.SEND_FORGOT_PASSWORD_EMAIL_START:
            return { ...state, isSendingForgotPasswordEmail: true, sendForgotPasswordEmailError: {} };
        case types.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
            return { ...state, isSendingForgotPasswordEmail: false };
        case types.SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
            return { ...state, isSendingForgotPasswordEmail: false, sendForgotPasswordEmailError: action.error };

        case types.SET_CREDENTIALS:
            return { ...state, credentials: action.data };
        case types.SET_PROFILE:
            return { ...state, profile: action.data };
        case types.SET_TOKEN:
            return { ...state, token: action.data };

        case types.UPDATE_AUTH:
            return action.data;
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        default:
            return state;
    }
}

export default authReducer;
