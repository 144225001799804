import './Error404.scss';

import React from 'react';
import { Helmet } from "react-helmet";

import A from 'components/A';
import H1 from 'components/H1';

const Error404 = () => {
    return (
        <div className="error-404">
            <Helmet>
                <title>404 Page Not Found</title>
            </Helmet>

            <div className="error-404__hero">
                <H1>
                    sorry, that page could not be found
                </H1>
            </div>

            <div className="error-404__content">
                <p>
                    You tried to reach a page which could not be found.
                </p>

                <p>
                    Please <A href={process.env.REACT_APP_WEBSITE_URL}>click here to visit the home page</A> or use the main menu to navigate to your desired location.
                </p>
            </div>
        </div>
    );
};

export default Error404;
