import * as types from './constants';
import Request from "../../../utils/request";
import PATHS from "../../../utils/paths";
import {generateFriendlyErrorString} from "../../../utils/data";
import {addError} from "../../Errors/store/actions";

export const clearEpilogg = () => ({
    type: types.CLEAR_EPILOGG
});


const deleteEpiloggStart = () => ({
    type: types.DELETE_EPILOGG_START
});

const deleteEpiloggSuccess = (data) => ({
    type: types.DELETE_EPILOGG_SUCCESS,
    data
});

const deleteEpiloggFailure = (error) => ({
    type: types.DELETE_EPILOGG_FAILURE,
    error
});


const getEpiloggsStart = (hideSpinner) => ({
    type: types.GET_EPILOGGS_START,
    hideSpinner
});

const getEpiloggsSuccess = (data, metadata) => ({
    type: types.GET_EPILOGGS_SUCCESS,
    data,
    metadata
});

const getEpiloggsFailure = (error) => ({
    type: types.GET_EPILOGGS_FAILURE,
    error
});

export const setEpilogg = (data) => ({
    type: types.SET_EPILOGG,
    data
});


const undeleteEpiloggStart = () => ({
    type: types.UNDELETE_EPILOGG_START
});

const undeleteEpiloggSuccess = (data) => ({
    type: types.UNDELETE_EPILOGG_SUCCESS,
    data
});

const undeleteEpiloggFailure = (error) => ({
    type: types.UNDELETE_EPILOGG_FAILURE,
    error
});


export const deleteEpilogg = (epilogg) => (dispatch, getStore) => {
    dispatch(deleteEpiloggStart());

    const request = new Request(getStore().auth.token);

    return request.delete(PATHS.epiloggs.delete(epilogg.id))
        .then(response => {
            dispatch(deleteEpiloggSuccess(epilogg));
            return response;
        })
        .catch(err => {
            console.log('deleteEpilogg error', err);
            err.friendlyMessage = generateFriendlyErrorString('Error deleting your epilogg. Please refresh the page.', err.response.data);
            dispatch(deleteEpiloggFailure(err));
            dispatch(addError(err));
            throw err;
        });
};


export const getEpiloggs = (isUpdate) => (dispatch, getStore) => {
    dispatch(getEpiloggsStart(isUpdate));

    const request = new Request(getStore().auth.token);

    let page = 0;

    if(isUpdate) {
        page = getStore().epiloggs.epiloggsMetadata.page + 1;
    }

    return request.get(PATHS.epiloggs.get(page))
        .then(response => {
            let items = response.data.data.items;
            if(isUpdate) {
                items = getStore().epiloggs.epiloggs.concat(items);
            }
            dispatch(getEpiloggsSuccess(items, { page }));
            return response;
        })
        .catch(err => {
            console.log('getEpiloggs error', err);
            err.friendlyMessage = generateFriendlyErrorString('Error getting the list of epiloggs. Please refresh the page.', err.response.data);
            dispatch(getEpiloggsFailure(err));
            dispatch(addError(err));
            throw err;
        });
};


export const undeleteEpilogg = (epilogg) => (dispatch, getStore) => {
    dispatch(undeleteEpiloggStart());

    const request = new Request(getStore().auth.token);

    return request.post(PATHS.epiloggs.undelete(epilogg.id))
        .then(response => {
            dispatch(undeleteEpiloggSuccess(epilogg));
            return response;
        })
        .catch(err => {
            console.log('undeleteEpilogg error', err);
            err.friendlyMessage = generateFriendlyErrorString('Error un-deleting your epilogg. Please refresh the page.', err.response.data);
            dispatch(undeleteEpiloggFailure(err));
            dispatch(addError(err));
            throw err;
        });
};
